.svg-icon {
  fill: currentColor;
}

.svg-icon:not([class*='w-']) {
  width: 1rem;
}

.svg-icon:not([class*='h-']) {
  height: 1rem;
}
