.steps {
  overflow: hidden;
}

.steps > * {
  position: relative;
  height: 4rem;
}

.steps > *:first-child:before,
.steps > *:last-child:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 10rem;
}

.steps > *:first-child:before {
  background-color: rgb(89, 82, 209);
  right: 100%;
}

.steps > *:last-child:after {
  background-color: rgb(229, 231, 235);
  left: 100%;
}

.steps > *.bg-primary:last-child:after {
  background-color: rgb(89, 82, 209);
}

.steps > *:not(:first-child) {
  padding-left: 2.5rem;
}

.steps > *:last-child {
  padding-left: 4rem;
}

.steps > *:not(:first-child):not(:last-child) {
  text-align: center;
}
