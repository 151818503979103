div.react-datepicker {
  font-family: Zeitung, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  border: 0;
  @apply shadow-lg;
}

div.react-datepicker__triangle {
  left: -1rem;
}

div.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before,
div.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after {
  border-bottom-color: #fff;
}

div.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
  border-bottom-color: #eee;
}

div.react-datepicker__header {
  background-color: transparent;
}

div.react-datepicker__day-name,
div.react-datepicker__day,
div.react-datepicker__time-name {
  margin: .25rem;
  width: 3rem;
  line-height: 3rem;
  @apply text-gray-400 rounded transition-colors border border-transparent;
}

div.react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled) {
  @apply bg-gray-100 text-gray-900;
}

div.react-datepicker__day:hover {
  @apply bg-gray-100;
}

div.react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled):hover {
  @apply bg-gray-300;
}

div.react-datepicker__day--weekend:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled) {
  @apply bg-white border border-gray-100;
}

div.react-datepicker__current-month,
div.react-datepicker-time__header,
div.react-datepicker-year-header {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: .75rem;
}

button.react-datepicker__navigation {
  top: .7rem;
  width: 1.8rem;
  height: 1.8rem;
  @apply bg-gray-200 rounded-full;
}

button.react-datepicker__navigation--previous {
  left: .7rem;
}

button.react-datepicker__navigation--next {
  right: .7rem;
}

span.react-datepicker__navigation-icon {
  line-height: 30px;
}

span.react-datepicker__navigation-icon--previous {
  right: 1px;
}

span.react-datepicker__navigation-icon--next {
  left: 3px;
}

span.react-datepicker__navigation-icon:before {
  border-color: #1d1550;
  right: -0.5rem;
  top: 1rem;
  width: .6rem;
  height: .6rem;
  border-width: 2px 2px 0 0;
}

div.react-datepicker__header {
  border-bottom: 0;
}

div.react-datepicker__day.react-datepicker__day--selected:not(.react-datepicker__day--outside-month),
div.react-datepicker__day--selected,
div.react-datepicker__day--in-selecting-range,
div.react-datepicker__day--in-range,
div.react-datepicker__month-text--selected,
div.react-datepicker__month-text--in-selecting-range,
div.react-datepicker__month-text--in-range,
div.react-datepicker__quarter-text--selected,
div.react-datepicker__quarter-text--in-selecting-range,
div.react-datepicker__quarter-text--in-range,
div.react-datepicker__year-text--selected,
div.react-datepicker__year-text--in-selecting-range,
div.react-datepicker__year-text--in-range {
  @apply bg-primary text-white;
}

button.react-datepicker__close-icon:after {
  @apply bg-gray-300 text-gray-500 p-0 w-6 h-6 opacity-40;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M20,16,31.17,4.82a2.82,2.82,0,0,0-4-4L16,12,4.82.83a2.82,2.82,0,0,0-4,4L12,16,.83,27.18a2.82,2.82,0,1,0,4,4L16,20,27.18,31.17a2.82,2.82,0,0,0,4-4Z'/%3E%3C/svg%3E");
  background-size: 8px auto;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 480px) {
  div.react-datepicker__day-name,
  div.react-datepicker__day,
  div.react-datepicker__time-name {
    margin: .3125rem;
    width: 3.5rem;
  }

  .react-datepicker--sm div.react-datepicker__day-name,
  .react-datepicker--sm div.react-datepicker__day,
  .react-datepicker--sm div.react-datepicker__time-name {
    width: 3rem;
    line-height: 2.5rem;
  }
}
