.steps > *:not(:last-child) > span {
	position: relative;
}

.steps > *:not(:last-child) > span:after,
.steps > *:not(:last-child) > span:before {
	content: '';
	left: 100%;
	top: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
  z-index: 1;
  transform: scaleX(.75);
  transform-origin: 0 0;
}

.steps > *:not(:last-child) > span:after {
	border-color: rgba(255, 0, 0, 0);
	border-left-color: rgb(229, 231, 235);
	border-width: 2rem;
	margin-top: -2rem;
}

.steps > *.bg-primary:not(:last-child) > span:after {
  border-left-color: rgb(89, 82, 209);
}

.steps > *:not(:last-child) > span:before {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: calc(2rem + 3px);
	margin-top: calc((2rem + 3px) * -1);
}
