div.rc-slider-track {
  @apply bg-primary h-3;
}

div.rc-slider-rail {
  @apply bg-gray-200 h-3;
}

div.rc-slider-handle {
  @apply h-8 w-8 border-0 bg-primary-darker;
  margin-top: calc(-1rem + .375rem);
}
