.navigation-item svg {
  width: 100%;
  height: 100%;
  color: #b2b2d5;
}

.navigation-item .is-active svg {
  color: #fff;
}

.navigation-item__icon {
  width: 1.5rem;
  height: 1.5rem;
}

.navigation-item .icon-vehicle {
  width: 90%;
  height: 90%;
}

.navigation-item .icon-pin {
  width: 105%;
  height: 105%;
}

.navigation-item .icon-route {
  width: 90%;
  height: 90%;
}
