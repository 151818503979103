.navigation__toggle {
  height: 4rem;
}

.navigation__toggle span,
.navigation__toggle span:before,
.navigation__toggle span:after {
  height: 2px;
  width: 1.375rem;
}

.navigation__toggle span:before,
.navigation__toggle span:after {
  content: '';
  position: absolute;
  left: 0;
  background-color: currentColor;
}

.navigation__toggle span:before {
  top: -.5rem;
}

.navigation__toggle span:after {
  top: .5rem;
}

@media (max-width: 767px) {
  .navigation {
    height: 4rem;
  }

  .navigation.is-expanded {
    height: auto;
  }
}

@media (min-width: 768px) {
  .navigation {
    width: 4rem;
    flex-basis: 4rem;
  }

  .navigation.is-expanded {
    width: 17.5rem;
    flex-basis: 17.5rem;
  }
}
